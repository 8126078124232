<template>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3>To</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-card
                      class="mx-auto py-5 px-5"
                      outlined
                    >
                      <v-row color="red">
                        <v-col cols="12" md="6">
                          <strong>Recipient:</strong><br />
                          {{ userShippingDetail.destination.last_name }} {{ userShippingDetail.destination.first_name }}
                        </v-col>
                        <v-col cols="12" md="6">
                          <strong>Contact:</strong><br />
                          {{ userShippingDetail.destination.contact_number }}
                        </v-col>
                        <v-col cols="12" md="6">
                          <strong>Address:</strong><br />
                          {{ userShippingDetail.address }}
                        </v-col>
                        <v-col cols="12" md="6">
                          <strong>Fee Payment:</strong><br />
                          {{ userShippingDetail.fee_payment }}
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3>Select items</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <item-table
                      ref="itemTable"
                      :selectable="true"
                      :removable="true"
                      :items="confirmedItems"
                      @updated="value => form.items = value"
                    ></item-table>
                  </v-col>
                </v-row>

              </v-col>

              <template v-if="form.items.length > 0">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <h3>Weight</h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.weight.value"
                        label="Value"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="form.weight.unit"
                        :items="weightUnitList"
                        item-text="name"
                        item-value="value"
                        label="Unit">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <h3>Dimension</h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="form.dimension.length"
                        label="Length"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="form.dimension.width"
                        label="Width"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="form.dimension.height"
                        label="Height"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="form.dimension.unit"
                        :items="lengthUnitList"
                        item-text="name"
                        item-value="value"
                        label="Unit">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <h3>Storage Type</h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="form.storage_type"
                        :items="storageTypeList"
                        item-text="name"
                        item-value="value"
                        >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col> -->

                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <h3>Ship Via</h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-card :color="form.channel == 'gateway' ? 'primary' : ''" @click="changeChannel('gateway')">
                        <v-card-text class="text-center" :class="form.channel == 'gateway' ? 'white--text ' : ''">
                          Gateway
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" md="6" sm="6">
                      <v-card :color="form.channel == 'merchant' ? 'primary' : ''" @click="changeChannel('merchant')">
                        <v-card-text class="text-center" :class="form.channel == 'merchant' ? 'white--text ' : ''">
                          Merchant
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                    <v-autocomplete
                      v-model="form.merchant_shipping_address_id"
                      :items="merchantShippingAddresses"
                      item-text="name"
                      item-value="id"
                      label="From Address"
                      @change="getEstimateShippingFee"
                    >
                    </v-autocomplete>
                </v-col>

                <template v-if="form.channel == 'gateway'">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.gateway_courier_id"
                      :items="shippingRates"
                      item-text="name"
                      item-value="id"
                      :loading="fetchingShippingRate"
                      :disabled="fetchingShippingRate || form.merchant_shipping_address_id == null"
                      :label="fetchingShippingRate ? 'Fechting shipping fee' : 'Choose Courier'"
                      hint="Estimated price. The final price is subject to the actual price."
                      persistent-hint
                    >
                    </v-autocomplete>
                  </v-col>
                </template>

                <template v-if="form.channel == 'merchant'">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.courier_id"
                      :items="courierList"
                      item-text="name"
                      item-value="id"
                      :label="'Choose Courier'"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="form.tracking_number"
                      hide-details
                      label="Tracking Number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                      <v-text-field
                        v-model="form.tracking_url"
                        hide-details
                        label="Tracking URL"
                      ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                      <v-select
                        v-model="form.status"
                        :items="statusList"
                        item-text="name"
                        item-value="value"
                        hide-details
                        label="Status"
                      ></v-select>
                  </v-col>
                </template>

                <v-col>
                  <v-textarea
                  v-model="form.remarks"
                    name="remarks"
                    label="Remarks"
                    hint="Optional"
                  ></v-textarea>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="submitting"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary outlined"
            text
            @click="handleFormSubmit"
            :loading="submitting"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
import { createOrder, getShippingRates } from '@/api/shipping/shipping-order'
import { OrderItemTable } from '@/components'
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import commonStore from '@/modules/common/store'
import merchantStore from '@/modules/merchant/store'
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  components: {
    OrderItemTable,
  },
  props: {
    order: {
      required: true,
      validator: value => typeof value === 'object' || value === null,
    },
  },
  setup(props, { emit }) {
    const itemTable = ref()
    const shippingFee = ref(0)
    const shippingRates = ref([])
    const fetchingShippingRate = ref(false)
    const submitting = ref(false)
    const statusList = ref([
      {
        value: 'preparing_for_shipment',
        name: 'Preparing For Shipment',
      },
      {
        value: 'shipped',
        name: 'Shipped',
      },
    ])

    // watch([props.order], () => {
    //   console.log('order udpated')
    // })

    const getInitialData = () => {
      return {
        merchant_id: props.order?.merchant_id,
        order_id: props.order?.id,
        items: [],
        merchant_shipping_address_id: null,
        weight: {
          value: 1,
          unit: 'kg',
        },
        dimension: {
          length: 10,
          width: 10,
          height: 10,
          unit: 'cm',
        },
        storage_type: 'normal',
        gateway_courier_id: null,
        channel: 'gateway',
        courier_id: null,
        tracking_number: null,
        tracking_url: null,
        remarks: null,
      }
    }

    const form = ref({ ...getInitialData() })

    // TODO: get from API and store in app store
    const weightUnitList = ref([
      {
        name: 'kg',
        value: 'kg',
      },
      {
        name: 'g',
        value: 'g',
      },
      {
        name: 'lb',
        value: 'lb',
      },
      {
        name: 'oz',
        value: 'oz',
      },
    ])

    const lengthUnitList = ref([
      {
        name: 'cm',
        value: 'cm',
      },
      {
        name: 'm',
        value: 'm',
      },
      {
        name: 'mm',
        value: 'mm',
      },
      {
        name: 'inch',
        value: 'inch',
      },
    ])

    const storageTypeList = ref([
      {
        name: 'Normal',
        value: 'normal',
      },
    ])

    // List items to ship
    const confirmedItems = computed(() => {
      if (props.order) {
        return props.order?.items.filter(item => {
          return item.status === 'confirmed'
        })
      }

      return []
    })

    // Get merchant info from merchant store
    const merchantShippingAddresses = computed(() => {
      if (props.order) {
        const merchant = merchantStore.state.all.find(merchant => {
          return merchant.id === props.order?.merchant_id
        })

        return merchant?.shipping_addresses.map(address => {
          return {
            name: `${address.address_line_1}`,
            id: address.id,
          }
        })
      }

      return []
    })

    const userShippingDetail = computed(() => {
      if (props.order) {
        return props.order?.shippings[0]
      }

      return {}
    })

    const courierList = computed(() => commonStore.state.couriers.map(courier => ({ name: courier.name.en, id: courier.id })))

    const getEstimateShippingFee = async () => {
      if (form.value.channel == 'merchant') {
        return null
      }

      if (form.value.merchant_shipping_address_id) {
        fetchingShippingRate.value = true

        try {
          const quotations = await getShippingRates(form.value)

          if (quotations) {
            shippingRates.value = quotations.map(quotation => {
              return {
                name: `${quotation.courier} ($30 up)`,
                id: quotation.courier_id,
              }
            })

            // auto select first one
            form.value.gateway_courier_id = shippingRates.value[0].id
          }
        } catch (error) {
          useNotifyErrors(error)
        } finally {
          fetchingShippingRate.value = false
        }
      }
    }

    const closeDialog = () => {
      form.value = getInitialData()
      itemTable.value.reset()

      emit('close')
    }

    const changeChannel = channel => {
      form.value.channel = channel
    }

    const handleFormSubmit = async () => {
      submitting.value = true

      try {
        const data = await createOrder(form.value)

        if (data) {
          emit('updated')

          closeDialog()
          useNotifySuccess({ content: 'Successfully created shipment' })
        }
      } catch (error) {
        useNotifyErrors(error)
      } finally {
        submitting.value = false
      }
    }

    return {
      getInitialData,

      shippingFee,
      shippingRates,
      fetchingShippingRate,

      itemTable,
      form,
      weightUnitList,
      lengthUnitList,
      storageTypeList,
      statusList,
      courierList,

      confirmedItems,
      merchantShippingAddresses,
      userShippingDetail,
      getEstimateShippingFee,

      closeDialog,
      changeChannel,
      submitting,
      handleFormSubmit,
    }
  },
})
</script>